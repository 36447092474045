import React from 'react';
import { graphql } from 'gatsby';
import { PageHero, PageWithSubNav, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Tagging"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Tagging" />

      <PlatformTabs>
        <WebTab data={data} type="web" />
        <ReactNativeTab data={data} type="reactnative" />
        <AndroidTab data={data} type="android" />
        <AppleTab data={data} type="apple" />
        <OtherTab data={data} type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query taggingColorsCode {
    allColorsYaml(filter: { type: { eq: "Tagging" } }) {
      edges {
        node {
          type
          colors {
            value
            hex_value
            android_var
            sass_var
            apple_var
            rn_var
          }
        }
      }
    }
  }
`;
