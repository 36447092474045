import React from 'react';
import { PlatformTab, PlatformTabEmptyState } from 'docComponents';

const OtherTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="other" pageType="guidelines" />
    </PlatformTab>
  );
};

export default OtherTab;
